<template>
	<div>
		<van-dropdown-menu>
			<van-dropdown-item v-model="value1" :title="title" :options="option1" @change="onChange" />
			<!-- <van-dropdown-item v-model="value2" :options="option2" /> -->
		</van-dropdown-menu>
		<div class="tabBox">
			<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList">
				<div v-for="(item,index) in tabList" @click="indexDetails(item.id)" class="tabList"
					v-if="tabList.length!=0">
					<div class="image">
						<img :src="item.image" alt="" style="width: 120px;height: 100px;">
					</div>
					<div class="rightInfo">
						<p class="nameRight">{{item.name}}</p>
						<div class="priceDis">
							<p class="price">￥{{item.price}}</p>
							<p class="distance" v-if="item.distance">{{item.distance}}m</p>
							<p class="distance" v-else>0m</p>
						</div>
					</div>
				</div>
				<van-empty v-else class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
					description="暂无商品" image-size="80px" />
			</van-list>
		</div>
	</div>
</template>

<script>
	import * as index from '@/api/index'
	export default {
		data() {
			return {
				value1: 0,
				value2: 'b',
				option1: [],
				title: '',
				option2: [{
						text: '默认排序',
						value: 'a'
					},
					{
						text: '好评排序',
						value: 'b'
					},
					{
						text: '销量排序',
						value: 'c'
					},
				],
				tabList: [],
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
			}
		},
		computed: {
		    location: function () {
				return this.$store.state.app.latAndLng
		    },
		   
		},
		methods: {

			onChange(value) {
				console.log(value)
				this.value1 = value
				this.getProductLst()
			},
			// 分类
			getCate() {
				let params = {
					type: 1
				}
				index.getCate(params).then((res) => {
					console.log(res)
					// for(let i = 0; i<res.data.data.length; i++){
					// 	let object =  {
					// 			text: res.data.data[i].name,
					// 			value: res.data.data[i].id,
					// 		}
					// 			this.option1.push(object)
					// }
					// this.option1=[]
					res.data.data.map(item => {
						let object = {
							text: item.name,
							value: item.id,
						}
						this.option1.push(object)
					})

					console.log(res.data.data)
					// this.option1 = res.data.data
					this.value1 = Number(this.$route.query.id)
				})
			},
			// 商品列表
			getProductLst() {
				let params = {
					page: this.page,
					limit: this.limit,
					cid: this.value1,
					lng :this.location.longitude,
					lat: this.location.latitude
					// keyword:this.keyword,
				}
				index.getProductLst(params).then((res) => {
					console.log(res)
					this.tabList = res.data.data;
					// if (rows.length === 0) {
					// 	// 加载结束
					// 	this.upFinished = true
					// 	if (this.page == 1) {
					
					// 		this.dataStatus = false
					// 		this.$toast.clear()
					// 	}
					// 	return
					// } else {
					// 	this.dataStatus = true
					// }
					// if (rows.length < this.limit) {
					// 	// 最后一页不足10条的情况
					// 	this.upFinished = true
					// }
					// // 处理数据
					// if (this.page === 1) {
					// 	this.tabList = rows
					// } else {
					// 	this.tabList = this.tabList.concat(rows)
					// }
					// // console.log(this.tabList)
					// this.$toast.clear()
					// console.log(this.tabContentList)
				})
			},
			indexDetails(id) {
				console.log(id)
				this.$router.push({
					path: "/h5/commDetail",
					query: {
						id: id
					}
				})
			},
		},
		mounted() {
			this.value1 = Number(this.$route.query.id)
			// this.title = this.$route.query.name
			this.getCate()
			this.getProductLst()
			console.log(this.$route.query.id)
			console.log(this.$route.query.name)
		},
		beforeRouteEnter(to, from, next) {
			console.log(from)
			next()
		}
	}
</script>

<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}

	.tabBox {
		padding: 5px 10px;

		.tabList {
			display: flex;
			font-size: 14px;
			background: #FFFFFF;
			align-items: center;
			box-shadow: 0 0 2px #d4d4d4;
			// justify-content: space-around;
			padding: 3px 5px;
			margin-bottom: 5px;

			.image {
				img {
					border-radius: 5px;
				}
			}

			.rightInfo {
				margin-left: 10px;
				width: 80%;

				.priceDis {
					margin-top: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.price {
						color: red;
					}

					.distance {
						color: #ccc;
					}
				}
			}
		}
	}
</style>
